const brand = {
  key: 'serendipity',
  path: 'serendipity',
  accessKey: process.env.REACT_APP_SERENDIPITY_ACCESS_KEY,
  name: 'Serendipity',
  website: {
    url: 'https://www.serendipityclo.com/fr',
    domain: 'Serendipityclo.com',
  },
  features: {
    withUidCheck: false,
    withReferenceInput: false,
    withTextRecognition: false,
    withIssueRequest: true,
  },
  images: {
    logo: 'https://res.cloudinary.com/dnkj85yan/image/upload/v1716992598/Serendipity/0001-02001-ezgif.com-crop_x2ctu8.gif',
    homePage:
      'https://res.cloudinary.com/dnkj85yan/image/upload/v1716969737/Serendipity/Screenshot_2024-05-17_at_20.27.02_ksopws.png',
    homePageCompressed:
      'https://res.cloudinary.com/dnkj85yan/image/upload/v1716969737/Serendipity/Screenshot_2024-05-17_at_20.27.02_ksopws.png',
    certificateExample: '',
    certificateExampleCompressed: '',
    favicon: '',
    uidExample: '',
  },
  socialLinks: {
    instagram: 'https://www.instagram.com/clothes_by_serendipity/',
    tiktok: 'https://www.tiktok.com/@serendipity.clo',
  },
};

export default brand;
